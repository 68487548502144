import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";
import Button from "../atoms/Button";
import { monthNames } from "../../helpers/all"

const RoofingCoupon = () => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "roofing_bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
      }
    `}
    render={(data) => {


  const dateInFiveDays = new Date();
  // deal expires in +5 days
  dateInFiveDays.setDate(dateInFiveDays.getDate() + 7);

  //date day suffix
  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const headerTransparent = false;

  var dealDateStringOutput =
    monthNames[dateInFiveDays.getUTCMonth() % 12] +
    " " +
    dateInFiveDays.getUTCDate() +
    nth(dateInFiveDays.getUTCDate());


    
      //temporary return null for Roach Roofing while we get team pic
      return (

        <div className="rounded-xl mb-2 shadow bg-white relative overflow-hidden">
        <div class="px-8 py-12 flex items-start space-x-4 z-10 relative min-h-[300px]">
         
          <div> 
            <h3 class="text-neutral-900 font-bold text-2xl md:text-3xl font-display"><span className="text-brand-700">$1,000 OFF</span></h3>
            <h3 class="text-neutral-900  font-bold text-lg md:text-2xl font-display">Full Roof Replacement</h3> 
 

            <p className="text-neutral-500 font-display font-normal text-sm mt-1">
            Minimum 2,000 sq. ft. roof. Not valid with any other offer. Expires {dealDateStringOutput}.

</p>
            <Button className="bg-white mt-4" to="/contact/">
             Claim Offer
            </Button>
          </div>
        </div>

        <GatsbyImage
        className="!absolute bottom-0 z-1 left-0 w-full"
          image={getImage(data.fileName.childImageSharp)}
          alt="Roofing Special Offer"
        />

          </div>


      );
    }}
  />
);
export default RoofingCoupon;

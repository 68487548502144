import React, { useRef } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from './card-general'
import { Check } from 'react-feather'

import { useHiddenmsClassCity } from '../hooks/useHiddenmsClass'

const SidebarHeader = styled.h4`
  margin-bottom: 0.5em;
`
const SidebarP = styled.p`
  color: #444;
`
const TextPhoneStyle = styled.span``

const ContactInfo = () => {
  const footerRefs = useHiddenmsClassCity({
    handler: (ref) => {
      const className = ref.current.classList
      ref.current.className = className + ' hiddenms'
    },
  })
  const addressRef = useRef()
  const addressHeadingRef = useRef()
  footerRefs.push(addressRef)
  footerRefs.push(addressHeadingRef)
  return (
    <StaticQuery
      query={graphql`
        query ContactInfoQuery {
          site {
            siteMetadata {
              companyInfo {
                phone
                tollfreephone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
        }
      `}
      render={(data) => {
        let { siteMetadata } = data.site
        return (
          <>
            <CardGeneral title={'Contact Us'}>
               

             

            <span className="block py-2">
                 
                <span className="block text-neutral-600">
                  {siteMetadata.companyInfo.phone}
                  <a href={`mailto:${siteMetadata.companyInfo.email}`} className="block">
                    {siteMetadata.companyInfo.email}
                  </a>
                </span>
              </span>
              <span className="block py-2">
                <span className="block text-neutral-800 font-semibold font-display">Location</span>{" "}
                <span className="block text-neutral-600">
                   
               
901 Capital Ave Northeast<br/> Battle Creek, Michigan
                </span>
              </span>
            </CardGeneral>
          </>
        )
      }}
    />
  )
}
export default ContactInfo
